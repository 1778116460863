<template>
  <div id="app">
    <PaymentButton />
  </div>
</template>

<script>
import PaymentButton from './components/PaymentButton.vue'

export default {
  components: {
    PaymentButton
  }
}
</script>