<template>
  <div class="container">
    <div class="payment-box">
      <h2 class="title">Оплата услуг</h2>
      <p class="description">
        Для продолжения оплаты нажмите кнопку ниже. После завершения вы получите уведомление о статусе транзакции. Если возникнет ошибка, не переживайте — всегда можно попробовать еще раз.      </p>
      <button class="pay-button" @click="handlePayment" :disabled="loading">
        {{ loading ? "Обработка..." : "Оплатить" }}
      </button>

      <div v-if="loading" class="loader"></div>

      <div v-if="paymentStatus" class="status-container">
        <div class="checkmark" v-if="paymentStatus === 'SUCCESS'"></div>
        <p :class="statusClass">{{ paymentStatusMessage }}</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      paymentStatus: null,
      paymentStatusMessage: '',
      loading: false,
    };
  },
  computed: {
    statusClass() {
      return this.paymentStatus === "SUCCESS" ? "success" : "failed";
    }
  },
  methods: {
    handlePayment() {
      this.loading = true;
      this.paymentStatus = null;

      setTimeout(() => {
        try {
          if (typeof kaspi === "undefined") {
            throw new Error("Сервис Kaspi недоступен");
          }
          kaspi.send(
              "opay",
              `${process.env.VUE_APP_CPP_URL}?type='Global'&transportId='${process.env.VUE_APP_TRANSPORT_ID}'`,
              this.callbackFunc
          );
        } catch (error) {
          this.loading = false;
          this.paymentStatus = "FAILED";
          this.paymentStatusMessage = error.message;
          console.error("Ошибка: ", error.message);
        }
      }, 2000);
    },

    callbackFunc(status) {
      this.loading = false;
      if (status === "SUCCESS") {
        this.paymentStatus = "SUCCESS";
        this.paymentStatusMessage = "Ваш платеж успешно завершен!";
      } else if (status === "FAILED") {
        this.paymentStatus = "FAILED";
        this.paymentStatusMessage = "Платеж не был завершен. Попробуйте снова.";
      }
    }
  }
}
</script>

<style scoped>
/* Основной контейнер, чтобы все было по центру */
.container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f0f0f0;
  padding: 20px;
}

/* Стиль блока оплаты */
.payment-box {
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
  width: 100%;
  max-width: 400px;
}

/* Заголовок */
.title {
  font-size: 24px;
  margin-bottom: 10px;
  color: #333;
}

/* Описание процесса оплаты */
.description {
  font-size: 16px;
  color: #666;
  margin-bottom: 20px;
}

/* Стиль кнопки */
.pay-button {
  background-color: #4CAF50;
  color: white;
  padding: 15px;
  border: none;
  border-radius: 5px;
  font-size: 18px;
  cursor: pointer;
  width: 100%;
  max-width: 300px;
  transition: background-color 0.3s ease;
}

.pay-button:disabled {
  background-color: #9E9E9E;
  cursor: not-allowed;
}

.pay-button:hover:enabled {
  background-color: #45a049;
}

/* Лоадер (анимация вращения) */
.loader {
  margin: 20px auto;
  border: 5px solid #f3f3f3;
  border-top: 5px solid #4CAF50;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

/* Контейнер для статуса */
.status-container {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* Галочка (анимация успешного платежа) */
.checkmark {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  display: inline-block;
  border: 3px solid #4CAF50;
  position: relative;
}

.checkmark::before {
  content: '';
  position: absolute;
  width: 25px;
  height: 12px;
  border-left: 3px solid #4CAF50;
  border-bottom: 3px solid #4CAF50;
  top: 15px;
  left: 10px;
  transform: rotate(-45deg);
}

/* Сообщение статуса */
p {
  margin-top: 10px;
  font-size: 18px;
  font-weight: bold;
}

.success {
  color: #4CAF50;
}

.failed {
  color: #E53935;
}

/* Адаптивная верстка */
@media screen and (max-width: 600px) {
  .payment-box {
    padding: 15px;
  }

  .pay-button {
    font-size: 16px;
    padding: 12px;
  }

  .title {
    font-size: 20px;
  }

  .description {
    font-size: 14px;
  }

  p {
    font-size: 16px;
  }

  .checkmark {
    width: 40px;
    height: 40px;
  }
}
</style>